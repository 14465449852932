import { LOAD_DATA, REFRESH_DATA, ADD_DATA, EDIT_DATA, EXPORT_DATA, LOAD_DATA2 } from "../actions/types";

const initialState = {
    list: null,
    data: null,
    export: null,
    total: 0,
    page: 0,
    loading: true,
};

export default function data2(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REFRESH_DATA:
            return {
                ...state,
                list: payload.data,
                total: payload.total,
                page: payload.page,
                loading: false,
            };
        case EXPORT_DATA:
            return {
                ...state,
                export: payload.data,
                loading: false,
            };
        case LOAD_DATA2:
        case ADD_DATA:
        case EDIT_DATA:
            return {
                ...state,
                data: payload.data,
                loading: false,
            };
        default:
            return state;
    }
}
