// alert.js
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// auth.js
export const USER_LOADED = "USER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RECOVERY_PASSWORD = "RECOVERY_PASSWORD";
export const CHANGE_PROFILE = "CHANGE_PROFILE";
export const AUTH_ERROR = "AUTH_ERROR";

// data.js
export const REFRESH_DATA = "REFRESH_DATA";
export const LOAD_DATA = "LOAD_DATA";
export const LOAD_DATA2 = "LOAD_DATA2";
export const ADD_DATA = "ADD_DATA";
export const EDIT_DATA = "EDIT_DATA";
export const DELETE_DATA = "DELETE_DATA";
export const EXPORT_DATA = "EXPORT_DATA";
export const UPLOAD_DATA = "UPLOAD_DATA";
export const CRUD_FAIL = "CRUD_FAIL";

// master.js
export const LOAD_USER = "LOAD_USER";
export const LOAD_ROLE = "LOAD_ROLE";
export const LOAD_MODULE = "LOAD_MODULE";
export const LOAD_PROJECT = "LOAD_PROJECT";
export const LOAD_QUOTATION = "LOAD_QUOTATION";
export const LOAD_QUOTATIONDETAIL = "LOAD_QUOTATIONDETAIL";
export const LOAD_CUSTOMER = "LOAD_CUSTOMER";
export const LOAD_CUSTOMERAPPROVAL = "LOAD_CUSTOMERAPPROVAL";
export const LOAD_CUSTOMERDECLINE = "LOAD_CUSTOMERDECLINED";
export const LOAD_CUSTOMERPURPOSE = "LOAD_CUSTOMERPURPOSE";
export const LOAD_CITIES = "LOAD_CITIES";
export const LOAD_QUOTATIONUSAGE = "LOAD_QUOTATIONUSAGE";
export const LOAD_STATUSCONSTRUCTION = "LOAD_STATUSCONSTRUCTION";
export const LOAD_STATUS = "LOAD_STATUS";
export const LOAD_ITEM = "LOAD_ITEM";
export const LOAD_SPECIFICATIONORIGIN = "LOAD_SPECIFICATIONORIGIN";
export const LOAD_SPECIFICATIONUSAGE = "LOAD_SPECIFICATIONUSAGE";
export const LOAD_ELEVATORUSAGE = "LOAD_ELEVATORUSAGE";
export const LOAD_ELEVATORPANORAMIC = "LOAD_ELEVATORPANORAMIC";
export const LOAD_ELEVATORMACHINEROOMLOCATION = "LOAD_ELEVATORMACHINEROOMLOCATION";
export const LOAD_ELEVATORRATEDCAPACITY = "LOAD_ELEVATORRATEDCAPACITY";
export const LOAD_ELEVATORCAPACITYONCOP = "LOAD_ELEVATORCAPACITYONCOP";
export const LOAD_ELEVATORRATEDSPEED = "LOAD_ELEVATORRATEDSPEED";
export const LOAD_ELEVATORMAINPOWER = "LOAD_ELEVATORMAINPOWER";
export const LOAD_ELEVATORTYPE = "LOAD_ELEVATORTYPE";
export const LOAD_ELEVATORNUMBEROFPERSONS = "LOAD_ELEVATORNUMBEROFPERSONS";
export const LOAD_ELEVATORLIGHTINGPOWER = "LOAD_ELEVATORLIGHTINGPOWER";
export const LOAD_ELEVATORFREQUENCY = "LOAD_ELEVATORFREQUENCY";
export const LOAD_ELEVATOROPERATIONCONTROL = "LOAD_ELEVATOROPERATIONCONTROL";
export const LOAD_ELEVATORHOISTWAYSIZE = "LOAD_ELEVATORHOISTWAYSIZE";
export const LOAD_ELEVATORCARHEIGHTCEILING = "LOAD_ELEVATORCARHEIGHTCEILING";
export const LOAD_ELEVATORCARDOOROPENINGTYPE = "LOAD_ELEVATORCARDOOROPENINGTYPE";
export const LOAD_ELEVATORFIRERATING = "LOAD_ELEVATORFIRERATING";
export const LOAD_ELEVATORGFLOORMATERIAL = "LOAD_ELEVATORGFLOORMATERIAL";
export const LOAD_ELEVATORTYPFLOORMATERIAL = "LOAD_ELEVATORTYPFLOORMATERIAL";
export const LOAD_ELEVATORGFLOORJAMBTYPE = "LOAD_ELEVATORGFLOORJAMBTYPE";
export const LOAD_ELEVATORGFLOORJAMBMATERIAL = "LOAD_ELEVATORGFLOORJAMBMATERIAL";
export const LOAD_ELEVATORTOPFLOORHALLCALLBUTTONMATERIAL = "LOAD_ELEVATORTOPFLOORHALLCALLBUTTONMATERIAL";
export const LOAD_ELEVATORTYPICALFLOORHALLCALLBUTTONMATERIAL = "LOAD_ELEVATORTYPICALFLOORHALLCALLBUTTONMATERIAL";
export const LOAD_ELEVATORGFLOORHALLCALLBUTTONMATERIAL = "LOAD_ELEVATORGFLOORHALLCALLBUTTONMATERIAL";
export const LOAD_ELEVATORBOTTOMFLOORHALLCALLBUTTONMATERIAL = "LOAD_ELEVATORBOTTOMFLOORHALLCALLBUTTONMATERIAL";
export const LOAD_ELEVATORGFLOORINDICATOR = "LOAD_ELEVATORGFLOORINDICATOR";
export const LOAD_ELEVATORTYPEHALLLANTERN = "LOAD_ELEVATORTYPEHALLLANTERN";
export const LOAD_ELEVATORSERVICEFLOORHALLLANTERN = "LOAD_ELEVATORSERVICEFLOORHALLLANTERN";
export const LOAD_ELEVATORWALLFINISHEDTHICKNESS = "LOAD_ELEVATORWALLFINISHEDTHICKNESS";
export const LOAD_ELEVATORCEILINGTYPE = "LOAD_ELEVATORCEILINGTYPE";
export const LOAD_ELEVATORCEILINGDECORATIVECOLOR = "LOAD_ELEVATORCEILINGDECORATIVECOLOR";
export const LOAD_ELEVATORTRANSOMMATERIAL = "LOAD_ELEVATORTRANSOMMATERIAL";
export const LOAD_ELEVATORKICKPLATE = "LOAD_ELEVATORKICKPLATE";
export const LOAD_ELEVATORCARDOORMATERIAL = "LOAD_ELEVATORCARDOORMATERIAL";
export const LOAD_ELEVATORSAFETYEDGE = "LOAD_ELEVATORSAFETYEDGE";
export const LOAD_ELEVATORPVCTYPE = "LOAD_ELEVATORPVCTYPE";
export const LOAD_ELEVATORMARBLES = "LOAD_ELEVATORMARBLES";
export const LOAD_ELEVATORFLOORPROVIDER = "LOAD_ELEVATORFLOORPROVIDER";
export const LOAD_ELEVATORHANDRAILTYPE = "LOAD_ELEVATORHANDRAILTYPE";
export const LOAD_ELEVATORHANDRAILPOSITION = "LOAD_ELEVATORHANDRAILPOSITION";
export const LOAD_ELEVATORSUBOPB = "LOAD_ELEVATORSUBOPB";
export const LOAD_ELEVATOROPBTYPE = "LOAD_ELEVATOROPBTYPE";
export const LOAD_ELEVATOROPBBUTTONTYPE = "LOAD_ELEVATOROPBBUTTONTYPE";
export const LOAD_ELEVATORCAROPERATINGPANELMATERIAL = "LOAD_ELEVATORCAROPERATINGPANELMATERIAL";
export const LOAD_ELEVATORINDICATORCAR = "LOAD_ELEVATORINDICATORCAR";
export const LOAD_ELEVATORHANDICAPPEDCOP = "LOAD_ELEVATORHANDICAPPEDCOP";
export const LOAD_ELEVATORMAINOPB = "LOAD_ELEVATORMAINOPB";
export const LOAD_ELEVATORSUBOPBCAR = "LOAD_ELEVATORSUBOPBCAR";
export const LOAD_ELEVATORHANDICAPPEDCOPCAR = "LOAD_ELEVATORHANDICAPPEDCOPCAR";
export const LOAD_ELEVATORCARREARWALLSIDEMATERIAL = "LOAD_ELEVATORCARREARWALLSIDEMATERIAL";
export const LOAD_ELEVATORCARREARWALLMIDDLEMATERIAL = "LOAD_ELEVATORCARREARWALLMIDDLEMATERIAL";
export const LOAD_ELEVATORCARSIDEWALLSIDEMATERIAL = "LOAD_ELEVATORCARSIDEWALLSIDEMATERIAL";
export const LOAD_ELEVATORCARSIDEWALLMIDDLEMATERIAL = "LOAD_ELEVATORCARSIDEWALLMIDDLEMATERIAL";
export const LOAD_ELEVATORCARFRONTWALLMATERIAL = "LOAD_ELEVATORCARFRONTWALLMATERIAL";
export const LOAD_ELEVATORCRT = "LOAD_ELEVATORCRT";
export const LOAD_ELEVATORHOISTWAYARRANGEMENT = "LOAD_ELEVATORHOISTWAYARRANGEMENT";
export const LOAD_ELEVATORHOISTWAYSTRUCTURE = "LOAD_ELEVATORHOISTWAYSTRUCTURE";
export const LOAD_ELEVATORRAILBRACKETPITCH = "LOAD_ELEVATORRAILBRACKETPITCH";
export const LOAD_ELEVATORCARRAILTYPE = "LOAD_ELEVATORCARRAILTYPE";
export const LOAD_ELEVATORCWTRAILTYPE = "LOAD_ELEVATORCWTRAILTYPE";
export const LOAD_ELEVATORCONTROLPANELLOCATION = "LOAD_ELEVATORCONTROLPANELLOCATION";
export const LOAD_ELEVATORHOISTWAYDIVIDEDBY = "LOAD_ELEVATORHOISTWAYDIVIDEDBY";
export const LOAD_ELEVATORTYPICALFLOORHALLCALLBUTTONTYPE = "LOAD_ELEVATORTYPICALFLOORHALLCALLBUTTONTYPE";
export const LOAD_ELEVATORHALLCALLBUTTONTYPE = "LOAD_ELEVATORHALLCALLBUTTONTYPE";
export const LOAD_ELEVATORBOTTOMFLOORHALLCALLBUTTONTYPE = "LOAD_ELEVATORBOTTOMFLOORHALLCALLBUTTONTYPE";
export const LOAD_ELEVATORTOPFLOORHALLCALLBUTTONTYPE = "LOAD_ELEVATORTOPFLOORHALLCALLBUTTONTYPE";
export const LOAD_ELEVATORGFLOORHALLCALLBUTTONTYPE = "LOAD_ELEVATORGFLOORHALLCALLBUTTONTYPE";

//ESCALATOR
export const LOAD_ESCALATORUSAGE = "LOAD_ESCALATORUSAGE";
export const LOAD_ESCALATORMODEL = "LOAD_ESCALATORMODEL";
export const LOAD_ESCALATORTYPE = "LOAD_ESCALATORTYPE";
export const LOAD_ESCALATORBALUSTRADETYPE = "LOAD_ESCALATORBALUSTRADETYPE";
export const LOAD_ESCALATORNOMINALWIDTH = "LOAD_ESCALATORNOMINALWIDTH";
export const LOAD_ESCALATORINCLINATIONANGEL = "LOAD_ESCALATORINCLINATIONANGEL";
export const LOAD_ESCALATORUSECONDITION = "LOAD_ESCALATORUSECONDITION";
export const LOAD_ESCALATORSPEED = "LOAD_ESCALATORSPEED";
export const LOAD_ESCALATORHORIZONTALSTEP = "LOAD_ESCALATORHORIZONTALSTEP";
export const LOAD_ESCALATORNATIONALCODE = "LOAD_ESCALATORNATIONALCODE";
export const LOAD_ESCALATORMAINPOWER = "LOAD_ESCALATORMAINPOWER";
export const LOAD_ESCALATORLIGHTINGPOWER = "ESCALATORLIGHTINGPOWER";
export const LOAD_ESCALATORFREQUENCY = "LOAD_ESCALATORFREQUENCY";
export const LOAD_ESCALATORARRANGEMENTTYPE = "LOAD_ESCALATORARRANGEMENTTYPE";
export const LOAD_ESCALATORDRIVEUNIT = "LOAD_ESCALATORDRIVEUNIT";
export const LOAD_ESCALATORCONTROLTYPE = "LOAD_ESCALATORCONTROLTYPE";
export const LOAD_ESCALATORDEGREEOFPROTECTION = "LOAD_ESCALATORDEGREEOFPROTECTION";
export const LOAD_ESCALATORTRUSSFINISH = "LOAD_ESCALATORTRUSSFINISH";
export const LOAD_ESCALATORSTEPCHAINROLLER = "LOAD_ESCALATORSTEPCHAINROLLER";
export const LOAD_ESCALATORSTEPCHAINTYPE = "LOAD_ESCALATORSTEPCHAINTYPE";
export const LOAD_ESCALATORWITHOUTINTERMEDIATESUPPORT = "LOAD_ESCALATORWITHOUTINTERMEDIATESUPPORT";
export const LOAD_ESCALATORGLASSJOINTYPE = "LOAD_ESCALATORGLASSJOINTYPE";
export const LOAD_ESCALATORINNEROUTERDECK = "LOAD_ESCALATORINNEROUTERDECK";
export const LOAD_ESCALATORINNEROUTERDECKTHICKNESS = "LOAD_ESCALATORINNEROUTERDECKTHICKNESS";
export const LOAD_ESCALATORNEWELSKIRT = "LOAD_ESCALATORNEWELSKIRT";
export const LOAD_ESCALATORSKIRTPANEL = "LOAD_ESCALATORSKIRTPANEL";
export const LOAD_ESCALATORSKIRTPANELTHICKNESS = "LOAD_ESCALATORSKIRTPANELTHICKNESS";
export const LOAD_ESCALATORSKIRTPANELFINISH = "LOAD_ESCALATORSKIRTPANELFINISH";
export const LOAD_ESCALATORSKIRTDEFLECTOR = "LOAD_ESCALATORSKIRTDEFLECTOR";
export const LOAD_ESCALATORHANDRAILFRAME = "LOAD_ESCALATORHANDRAILFRAME";
export const LOAD_ESCALATORHANDRAILCOLOR = "LOAD_ESCALATORHANDRAILCOLOR";
export const LOAD_ESCALATORHANDRAILMAKER = "LOAD_ESCALATORHANDRAILMAKER";
export const LOAD_ESCALATORSTEPMATERIAL = "LOAD_ESCALATORSTEPMATERIAL";
export const LOAD_ESCALATORSTEPCOLOR = "LOAD_ESCALATORSTEPCOLOR";
export const LOAD_ESCALATORFLOORPLATE = "LOAD_ESCALATORFLOORPLATE";
export const LOAD_ESCALATORFLOORPLATEETCHING = "LOAD_ESCALATORFLOORPLATEETCHING";
export const LOAD_ESCALATORFLOORMARK = "LOAD_ESCALATORFLOORMARK";
export const LOAD_ESCALATORFLOORPAINT = "LOAD_ESCALATORFLOORPAINT";
export const LOAD_ESCALATORCOMBFINGER = "LOAD_ESCALATORCOMBFINGER";
export const LOAD_ESCALATORHYUNDAILOGO = "LOAD_ESCALATORHYUNDAILOGO";
export const LOAD_ESCALATORSKIRTLIGHTINGLED = "LOAD_ESCALATORSKIRTLIGHTINGLED";
export const LOAD_ESCALATORHANDRAILLIGHTINGLED = "LOAD_ESCALATORHANDRAILLIGHTINGLED";
export const LOAD_ESCALATORDIRECTIONINDICATOR = "LOAD_ESCALATORDIRECTIONINDICATOR";
export const LOAD_ESCALATORFAULTFINDER = "LOAD_ESCALATORFAULTFINDER";
export const LOAD_ESCALATORCABLE = "LOAD_ESCALATORCABLE";
export const LOAD_ESCALATORPROTECTIONCABLE = "LOAD_ESCALATORPROTECTIONCABLE";
export const LOAD_ESCALATORAUTOMATICOPERATION = "LOAD_ESCALATORAUTOMATICOPERATION";
export const LOAD_ESCALATORINVERTER = "LOAD_ESCALATORINVERTER";
export const LOAD_ESCALATORSUPERVISORYPANEL = "LOAD_ESCALATORSUPERVISORYPANEL";
export const LOAD_ESCALATORUNDERSTEPLIGHTINGLED = "LOAD_ESCALATORUNDERSTEPLIGHTINGLED";
export const LOAD_ESCALATORCOMBLIGHTINGLED = "LOAD_ESCALATORCOMBLIGHTINGLED";
export const LOAD_ESCALATOREXTERIORCLADDING = "LOAD_ESCALATOREXTERIORCLADDING";
export const LOAD_ESCALATORMAXWEIGHTCLADDING = "LOAD_ESCALATORMAXWEIGHTCLADDING";


export const LOAD_ESCALATORHIGHEFFICIENCYMOTOR = "LOAD_ESCALATORHIGHEFFICIENCYMOTOR";
export const LOAD_ESCALATORAUXILIARYBRAKERISE = "LOAD_ESCALATORAUXILIARYBRAKERISE";
export const LOAD_ESCALATORAUTOLUBRICATION = "LOAD_ESCALATORAUTOLUBRICATION";
export const LOAD_ESCALATORFLOORPAINTTYPEFACEDIRECTION = "LOAD_ESCALATORFLOORPAINTTYPEFACEDIRECTION";
export const LOAD_ESCALATORCONTROLSTATION = "LOAD_ESCALATORCONTROLSTATION";
export const LOAD_ESCALATORNEGATIVEPHASEDETECTOR = "LOAD_ESCALATORNEGATIVEPHASEDETECTOR";
export const LOAD_ESCALATOROVERLOADRELAY = "LOAD_ESCALATOROVERLOADRELAY";
export const LOAD_ESCALATORUNDEROVERSPEEDSENSOR = "LOAD_ESCALATORUNDEOVERSPEEDSENSOR";
export const LOAD_ESCALATORNONREVERSAL = "LOAD_ESCALATORNONREVERSAL";
export const LOAD_ESCALATORBROKENSTEPSWITCH = "LOAD_ESCALATORBROKENSSTEPSWITCH";
export const LOAD_ESCALATORDISCONNECTSWITCH = "LOAD_ESCALATORDISCONNECTSWITCH";
export const LOAD_ESCALATOREMERGENCYSTOPSWITCH = "LOAD_ESCALATOREMERGENCYSTOPSWITCH";
export const LOAD_ESCALATORHANDRAILGUARDSWITCH = "LOAD_ESCALATORHANDRAILGUARDSWITCH";
export const LOAD_ESCALATORCOMBPLATESWITCH = "LOAD_ESCALATORCOMBPLATESWITCH";
export const LOAD_ESCALATORBROKENSTEPCHAINSWITCH = "LOAD_ESCALATORBROKENSTEPCHAINSWITCH";
export const LOAD_ESCALATORFLOORPLATESWITCH = "LOAD_ESCALATORFLOORPLATESWITCH";
export const LOAD_ESCALATORMOTORCOILOVERHEATINGSENSOR = "LOAD_ESCALATORMOTORCOILOVERHEATINGSENSOR";
export const LOAD_ESCALATORDRIVECHAINCOVEROILTRAYEN115 = "LOAD_ESCALATORDRIVECHAINCOVEROILTRAYEN115";
export const LOAD_ESCALATORSTEPSAFETYGUARDEN115 = "LOAD_ESCALATORSTEPSAFETYGUARDEN115";
export const LOAD_ESCALATORBRAKEOPENDETECTSWITCHEN115 = "LOAD_ESCALATORBRAKEOPENDETECTSWITCHEN115";
export const LOAD_ESCALATORHANDRAILSPEEDTESTEN115 = "LOAD_ESCALATORHANDRAILSPEEDTESTEN115";
export const LOAD_ESCALATORSTEPMISSINGSWITCHEN115 = "LOAD_ESCALATORSTEPMISSINGSWITCHEN115"
export const LOAD_ESCALATORFLYWHEELCOVERSWITCHHANDWINDINGDEVICEDETECTIONSWITCHEN115 = "LOAD_ESCALATORFLYWHEELCOVERSWITCHHANDWINDINGDEVICEDETECTIONSWITCHEN115";
export const LOAD_ESCALATORFLYWHEELCOVEREN115 = "LOAD_ESCALATORFLYWHEELCOVEREN115";
export const LOAD_ESCALATORSTATICOFPREVENTIONDEVICEHANDRAILSTEP = "LOAD_ESCALATORSTATICOFPREVENTIONDEVICEHANDRAILSTEP";
export const LOAD_ESCALATORSKIRTSWITCH = "LOAD_ESCALATORSKIRTSWITCH";
export const LOAD_ESCALATORBROKENMAINDRIVECHAINSWITCH = "LOAD_ESCALATORBROKENMAINDRIVECHAINSWITCH";
export const LOAD_ESCALATORSTEPUPTHRUSTSWITCH = "LOAD_ESCALATORSTEPUPTHRUSTSWITCH";
export const LOAD_ESCALATORMIDDLEEMERGENCYSTOPSWITCH = "LOAD_ESCALATORMIDDLEEMERGENCYSTOPSWITCH";
export const LOAD_ESCALATORFLOATSWITCH = "LOAD_ESCALATORFLOATSWITCH";
export const LOAD_ESCALATOREMERGENCYSTOPCOVERWITHBUZZER = "LOAD_ESCALATOREMERGENCYSTOPCOVERWITHBUZZER";
export const LOAD_ESCALATORHEATER = "LOAD_ESCALATORHEATER";
export const LOAD_ESCALATORHANDRAILHEATINGCABLE = "LOAD_ESCALATORHANDRAILHEATINGCABLE";
export const LOAD_ESCALATOROILWATERSEPARATOR = "LOAD_ESCALATOROILWATERSEPARATOR";
export const LOAD_ESCALATORPHASEADVANCECAPACITOR = "LOAD_ESCALATORPHASEAADVANCECAPACITOR";
export const LOAD_ESCALATORDEMARCATION = "LOAD_ESCALATORDEMARCATION";


//Dumbwaiter
//export const LOAD_DUMBWAITERNO = "LOAD_DUMBWAITERNO";
//export const LOAD_DUMBWAITERKODE = "LOAD_DUMBWAITERKODE";
export const LOAD_DUMBWAITERTYPE = "LOAD_DUMBWAITERTYPE";
export const LOAD_DUMBWAITERUSED = "LOAD_DUMBWAITERUSED";
export const LOAD_DUMBWAITERCAPACITY = "LOAD_DUMBWAITERCAPACITY";
export const LOAD_DUMBWAITERSPEED = "LOAD_DUMBWAITERSPEED";
export const LOAD_DUMBWAITERSERVICEDFLOOR = "LOAD_DUMBWAITERSERVICEDFLOOR";
export const LOAD_DUMBWAITERFINISHING = "LOAD_DUMBWAITERFINISHING";
export const LOAD_DUMBWAITERHOISTWAY = "LOAD_DUMBWAITERHOISTWAY";
//export const LOAD_DUMBWAITERCARSIZE = "LOAD_DUMBWAITERCARSIZE";
//export const LOAD_DUMBWAITERCLEAROPENING = "LOAD_DUMBWAITERCLEAROPENING";
//export const LOAD_DUMBWAITERPITOH = "LOAD_DUMBWAITERPITOH";

//Moving Walk
export const LOAD_MOVINGWALKUSAGE = "LOAD_MOVINGWALKUSAGE";
export const LOAD_MOVINGWALKMODEL = "LOAD_MOVINGWALKMODEL";
export const LOAD_MOVINGWALKTYPE = "LOAD_MOVINGWALKTYPE";
export const LOAD_MOVINGWALKBALUSTRADETYPE = "LOAD_MOVINGWALKBALUSTRADETYPE";
export const LOAD_MOVINGWALKNOMINALWIDTH = "LOAD_MOVINGWALKNOMINALWIDTH";
export const LOAD_MOVINGWALKINCLINATIONANGLE = "LOAD_MOVINGWALKINCLINATIONANGLE";
export const LOAD_MOVINGWALKUSECONDITION = "LOAD_MOVINGWALKUSECONDITION";
export const LOAD_MOVINGWALKSPEED = "LOAD_MOVINGWALKSPEED";
export const LOAD_MOVINGWALKNATIONALCODE = "LOAD_MOVINGWALKNATIONALCODE";
export const LOAD_MOVINGWALKPOWERDATAMAINPOWER = "LOAD_MOVINGWALKPOWERDATAMAINPOWER";
export const LOAD_MOVINGWALKPOWERDATALIGHTINGPOWER = "LOAD_MOVINGWALKPOWERDATALIGHTINGPOWER";
export const LOAD_MOVINGWALKPOWERDATAFREQUENZY = "LOAD_MOVINGWALKPOWERDATAFREQUENZY";
export const LOAD_MOVINGWALKARRANGEMENTTYPE = "LOAD_MOVINGWALKARRANGEMENTTYPE";
export const LOAD_MOVINGWALKDRIVEUNIT = "LOAD_MOVINGWALKDRIVEUNIT";
export const LOAD_MOVINGWALKHIGHEFFICIENCYMOTOR = "LOAD_MOVINGWALKHIGHEFFICIENCYMOTOR";
export const LOAD_MOVINGWALKCONTROLTYPE = "LOAD_MOVINGWALKCONTROLTYPE";
export const LOAD_MOVINGWALKDEGREEOFPROTECTION = "LOAD_MOVINGWALKDEGREEOFPROTECTION";
export const LOAD_MOVINGWALKAUXILIARYBRAKE = "LOAD_MOVINGWALKAUXILIARYBRAKE";
export const LOAD_MOVINGWALKTRUSSFINISH = "LOAD_MOVINGWALKTRUSSFINISH";
export const LOAD_MOVINGWALKPALLETCHAINROLLER = "LOAD_MOVINGWALKPALLETCHAINROLLER";
export const LOAD_MOVINGWALKPALLETCHAINTYPEBRAND = "LOAD_MOVINGWALKPALLETCHAINTYPEBRAND";
export const LOAD_MOVINGWALKWITHOUTINTERMEDIATESUPPORT = "LOAD_MOVINGWALKWITHOUTINTERMEDIATESUPPORT";
export const LOAD_MOVINGWALKAUTORUBRICATION = " LOAD_MOVINGWALKAUTORUBRICATION";
export const LOAD_MOVINGWALKGLASSJOINTTYPE = "LOAD_MOVINGWALKGLASSJOINTTYPE";
export const LOAD_MOVINGWALKINNEROUTERDECK = "LOAD_MOVINGWALKINNEROUTERDECK";
export const LOAD_MOVINGWALKINNEROUTERDECKTHICKNESS = "LOAD_MOVINGWALKINNEROUTERDECKTHICKNESS";
export const LOAD_MOVINGWALKNEWELSKIRT = "LOAD_MOVINGWALKNEWELSKIRT";
export const LOAD_MOVINGWALKSKIRTPANEL = "LOAD_MOVINGWALKSKIRTPANEL";
export const LOAD_MOVINGWALKSKIRTPANELTHICKNESS = "LOAD_MOVINGWALKSKIRTPANELTHICKNESS";
export const LOAD_MOVINGWALKSKIRTPANELFINISH = "LOAD_MOVINGWALKSKIRTPANELFINISH";
export const LOAD_MOVINGWALKSKIRTDEFLECTOR = "LOAD_MOVINGWALKSKIRTDEFLECTOR";
export const LOAD_MOVINGWALKHANDRAILFRAME = "LOAD_MOVINGWALKHANDRAILFRAME";
export const LOAD_MOVINGWALKHANDRAILCOLOR = "LOAD_MOVINGWALKHANDRAILCOLOR";
export const LOAD_MOVINGWALKHANDRAILMAKER = "LOAD_MOVINGWALKHANDRAILMAKER";
export const LOAD_MOVINGWALKPALLETMATERIAL = "LOAD_MOVINGWALKPALLETMATERIAL";
export const LOAD_MOVINGWALKPALLETCOLOR = "LOAD_MOVINGWALKPALLETCOLOR";
export const LOAD_MOVINGWALKFLOORPLATE = "LOAD_MOVINGWALKFLOORPLATE";
export const LOAD_MOVINGWALKFLOORPLATEETCHINGNO = "LOAD_MOVINGWALKFLOORPLATEETCHINGNO";
export const LOAD_MOVINGWALKFLOORMARK = "LOAD_MOVINGWALKFLOORMARK";
export const LOAD_MOVINGWALKFLOORPAINTTYPEFACEDIRECTION = "LOAD_MOVINGWALKFLOORPAINTTYPEFACEDIRECTION";
export const LOAD_MOVINGWALKFLOORPAINT = "LOAD_MOVINGWALKFLOORPAINT";
export const LOAD_MOVINGWALKCOMBFINGER = "LOAD_MOVINGWALKCOMBFINGER";
export const LOAD_MOVINGWALKHYUNDAILOGO = "LOAD_MOVINGWALKHYUNDAILOGO";
export const LOAD_MOVINGWALKCONTROLSTATION = "LOAD_MOVINGWALKCONTROLSTATION";
export const LOAD_MOVINGWALKNEGATIVEPHASEDETECTOR = "LOAD_MOVINGWALKNEGATIVEPHASEDETECTOR";
export const LOAD_MOVINGWALKOVERLOADRELAY = "LOAD_MOVINGWALKOVERLOADRELAY";
export const LOAD_MOVINGWALKUNDEROVERSPEEDSENSOR = "LOAD_MOVINGWALKUNDEROVERSPEEDSENSOR";
export const LOAD_MOVINGWALKNONREVERSAL = "LOAD_MOVINGWALKNONREVERSAL";
export const LOAD_MOVINGWALKBROKENSTEPSWITCH = "LOAD_MOVINGWALKBROKENSTEPSWITCH";
export const LOAD_MOVINGWALKDISCONNECTSWITCH = "LOAD_MOVINGWALKDISCONNECTSWITCH";
export const LOAD_MOVINGWALKEMERGENCYSTOPSWITCH = "LOAD_MOVINGWALKEMERGENCYSTOPSWITCH";
export const LOAD_MOVINGWALKHANDRAILGUARDSWITCH = "LOAD_MOVINGWALKHANDRAILGUARDSWITCH";
export const LOAD_MOVINGWALKCOMBPLATESWITCH = "LOAD_MOVINGWALKCOMBPLATESWITCH";
export const LOAD_MOVINGWALKBROKENSTEPCHAINSWITCH = "LOAD_MOVINGWALKBROKENSTEPCHAINSWITCH";
export const LOAD_MOVINGWALKFLOORPLATESWITCH = "LOAD_MOVINGWALKFLOORPLATESWITCH";
export const LOAD_MOVINGWALKMOTORCOILOVERHEATINGSENSOR = "LOAD_MOVINGWALKMOTORCOILOVERHEATINGSENSOR";
export const LOAD_MOVINGWALKDRIVECHAINCOVEROILTRAY = "LOAD_MOVINGWALKDRIVECHAINCOVEROILTRAY";
export const LOAD_MOVINGWALKPALLETSAFETYGUARD = "LOAD_MOVINGWALKPALLETSAFETYGUARD";
export const LOAD_MOVINGWALKBRAKEOPENDETECTSWITCH = "LOAD_MOVINGWALKBRAKEOPENDETECTSWITCH";
export const LOAD_MOVINGWALKHANDRAILSPEEDTEST = "LOAD_MOVINGWALKHANDRAILSPEEDTEST";
export const LOAD_MOVINGWALKSTEPMISSINGSSWITCH = "LOAD_MOVINGWALKSTEPMISSINGSSWITCH";
export const LOAD_MOVINGWALKFLYWHEELCOVERSWITCH = "LOAD_MOVINGWALKFLYWHEELCOVERSWITCH";
export const LOAD_MOVINGWALKFLYWHEELCOVER = "LOAD_MOVINGWALKFLYWHEELCOVER";
export const LOAD_MOVINGWALKDIRECTIONINDICATOR = "LOAD_MOVINGWALKDIRECTIONINDICATOR";
export const LOAD_MOVINGWALKFAULTFINDER = "LOAD_MOVINGWALKFAULTFINDER";
export const LOAD_MOVINGWALKCABLE = "LOAD_MOVINGWALKCABLE";
export const LOAD_MOVINGWALKPROTECTIONFORCABLE = "LOAD_MOVINGWALKPROTECTIONFORCABLE";
export const LOAD_MOVINGWALKSTATICOFPREVENTIONDEVICE = "LOAD_MOVINGWALKSTATICOFPREVENTIONDEVICE";
export const LOAD_MOVINGWALKAUTOMATICOPERATION = "LOAD_MOVINGWALKAUTOMATICOPERATION";
export const LOAD_MOVINGWALKINVERTER = "LOAD_MOVINGWALKINVERTER";
export const LOAD_MOVINGWALKSUPERVISORYPANEL = "LOAD_MOVINGWALKSUPERVISORYPANEL";
export const LOAD_MOVINGWALKSKIRTSWITCH = "LOAD_MOVINGWALKSKIRTSWITCH";
export const LOAD_MOVINGWALKBROKENMAINDRIVECHAINSWITCH = "LOAD_MOVINGWALKBROKENMAINDRIVECHAINSWITCH";
export const LOAD_MOVINGWALKPALLETUPTHRUSTSWITCH = "LOAD_MOVINGWALKPALLETUPTHRUSTSWITCH";
export const LOAD_MOVINGWALKMIDDLEEMERGENCYSTOPSWITCH = "LOAD_MOVINGWALKMIDDLEEMERGENCYSTOPSWITCH";
export const LOAD_MOVINGWALKFLOATSWITCH = "export const LOAD_MOVINGWALKFLOATSWITCH";
export const LOAD_MOVINGWALKUNDERPALLETLIGHTINGLED = "LOAD_MOVINGWALKUNDERPALLETLIGHTINGLED";
export const LOAD_MOVINGWALKEMERGENCYSTOPCOVERWITHBUZZER = "LOAD_MOVINGWALKEMERGENCYSTOPCOVERWITHBUZZER";
export const LOAD_MOVINGWALKHEATER = "LOAD_MOVINGWALKHEATER";
export const LOAD_MOVINGWALKHANDRAILHEATINGCABLE = "LOAD_MOVINGWALKHANDRAILHEATINGCABLE";
export const LOAD_MOVINGWALKOILWATERSEPARATOR = "LOAD_MOVINGWALKOILWATERSEPARATOR";
export const LOAD_MOVINGWALKPHASEADVANCECAPACITOR = "LOAD_MOVINGWALKPHASEADVANCECAPACITOR";
export const LOAD_MOVINGWALKEXTERIORCLADDING = "LOAD_MOVINGWALKEXTERIORCLADDING";
export const LOAD_MOVINGWALKMAXWEIGHTOFEXTERIORCLADDING = "LOAD_MOVINGWALKMAXWEIGHTOFEXTERIORCLADDING";
export const LOAD_CALCULATOR = "LOAD_CALCULATOR";
export const LOAD_CALCULATORDETAIL = "LOAD_CALCULATORDETAIL";